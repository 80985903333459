
import { computed, defineComponent, PropType, ref } from 'vue';

import { mdiPencil, mdiDelete, mdiArrowRight, mdiDragVariant, mdiContentSave } from '@mdi/js';

import { formatDate } from '@/i18n';
import store from '@/store';

type CareerRecord = {
  id: number;
  name: string;
  location: string;
  company: string;
  link: string;
  details: string;
  createdat: Date;
};

export default defineComponent({
  props: {
    careerentry: { type: Object as PropType<CareerRecord>, required: true },
  },
  emits: ['deleted'],
  setup(props, { emit }) {
    const confirmdelete = ref(false);
    const edit = ref(false);

    const name = ref();
    const location = ref();
    const company = ref();
    const link = ref();
    const details = ref();

    const entrylink = computed(() => {
      if (props.careerentry.link) {
        if (!props.careerentry.link.startsWith('http')) {
          return `https://karriere.styria.com${props.careerentry.link}`;
        } else {
          return props.careerentry.link;
        }
      }
      return '#';
    });

    const del = () => {
      if (!confirmdelete.value) {
        confirmdelete.value = true;
        setTimeout(() => {
          confirmdelete.value = false;
        }, 1000);
      } else {
        store.dispatch('deleteCareer', { id: props.careerentry.id }).then(() => {
          store.dispatch('addNotification', { message: 'Eintrag gelöscht' });
          emit('deleted');
        });
      }
    };

    const setedit = () => {
      edit.value = true;
      name.value = props.careerentry.name;
      location.value = props.careerentry.location;
      company.value = props.careerentry.company;
      link.value = props.careerentry.link;
      details.value = props.careerentry.details;
    };
    const save = () => {
      let error = false;
      if (!name.value || name.value == '') {
        store.dispatch('addNotification', { message: 'Bitte geben Sie einen Titel ein', type: 'error' });
        error = true;
      }
      if (!location.value || location.value == '') {
        store.dispatch('addNotification', { message: 'Bitte geben Sie einen Standort ein', type: 'error' });
        error = true;
      }

      // if (!company.value || company.value == '') {
      //   store.dispatch('addNotification', { message: 'Bitte geben Sie eine Firma ein', type: 'error' });
      //   error = true;
      // }

      if (!link.value || link.value == '') {
        store.dispatch('addNotification', { message: 'Bitte geben Sie einen Link ein', type: 'error' });
        error = true;
      }

      if (!details.value || details.value == '') {
        store.dispatch('addNotification', { message: 'Bitte geben Sie Details ein', type: 'error' });
        error = true;
      }

      if (!error) {
        store
          .dispatch('updateCareer', {
            id: props.careerentry.id,
            name: name.value,
            location: location.value,
            // company: company.value,
            link: link.value,
            details: details.value,
          })
          .then(() => {
            store.dispatch('addNotification', { message: 'Gespeichert' });
            emit('deleted');
            edit.value = false;
          })
          .catch(() => {
            store.dispatch('addNotification', { message: 'Fehler beim Speichern', type: 'error' });
          });
      }
    };

    return {
      date: formatDate(props.careerentry.createdat, 'DD.MM.YYYY HH:MM'),
      save,
      del,
      setedit,
      edit,
      mdiPencil,
      mdiDelete,
      mdiContentSave,
      mdiArrowRight,
      confirmdelete,
      mdiDragVariant,
      name,
      location,
      company,
      link,
      entrylink,
      details,
    };
  },
});

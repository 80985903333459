<style lang="scss">
#backend {
  .career-entry {
    //border:1px solid blue;
    margin-bottom: 30px;
    padding-bottom: 30px;
  }
  .career-overview {
    .career-inner {
      transition: $transition-std;
      border-bottom: 1px solid #eee;
      padding: 25px 15px;
      //margin-bottom:10px;
      &:last-child {
        border-bottom: 0;
      }
      .btn {
        border: 1px solid #eee;
        margin-right: 5px;
        svg {
          width: 20px;
          height: 20px;
          fill: #888;
        }
        &:hover {
          svg {
            fill: #333;
          }
        }
      }
      .btn-edit {
        margin-bottom: 5px;
      }
      .title {
        //border:1px solid blue;
        font-size: 120%;
        font-family: 'Helvetica Neue LT W05 65 Medium';
      }
      .details {
        //border:1px solid green;
      }
      .location {
      }
      .link {
        text-align: center;
        a {
          display: block;
          transition: $transition-std;
          svg {
            fill: #888;
            width: 30px;
            height: 30px;
          }
        }
      }
      &:hover {
        background: #f1f1f1;
        a {
          padding-left: 25px;
          svg {
            fill: #000;
          }
        }
      }
    }
  }
}
</style>

<template>
  <div class="container-fluid career-all">
    <div class="headline-h1">
      <h1>Eintrag hinzufügen</h1>
      <div class="line"><span>Karriere</span></div>
    </div>
    <div class="container-fluid career-entry">
      <div class="row">
        <div class="col-lg-4">
          <div class="form-group"><span class="label-input">Titel Beruf </span> <input type="text" v-model="career.name" /></div>
        </div>
        <div class="col-lg-4">
          <div class="form-group"><span class="label-input">Details</span> <input type="text" v-model="career.details" /></div>
        </div>
        <div class="col-lg-4">
          <div class="form-group"><span class="label-input">Location</span> <input type="text" v-model="career.location" /></div>
        </div>
        <div class="col-lg-6">
          <div class="form-group"><span class="label-input">Firmenname</span> <input type="text" v-model="career.company" /></div>
        </div>
        <div class="col-lg-6">
          <div class="form-group"><span class="label-input">Link zum Job</span> <input type="text" v-model="career.link" /></div>
        </div>
      </div>
      <div class="row justify-content-start align-self-center">
        <div class="col-lg-4 col-md-6">
          <button class="btn-std btn-color-save" @click="save">Speichern</button>
        </div>
      </div>
    </div>

    <div class="headline-h1">
      <h1>Übersicht</h1>
      <div class="line"><span>Karriere</span></div>
    </div>
    <div class="career-overview">
      <draggable v-model="careers" item-key="id" handle=".btn-move" tag="transition-group" :component-data="{ name: 'flip-list', type: 'transition' }" @change="saveOrder">
        <template #item="{element}">
          <CareerEntry :careerentry="element" :key="element.id" @deleted="load" />
        </template>
      </draggable>
    </div>
  </div>
</template>

<script lang="ts">
import CareerEntry from '../../components/Backend/Career/CareerEntry.vue';
import { defineComponent, onMounted, ref } from 'vue';
import { useStore } from 'vuex';

import draggable from 'vuedraggable';

type CareerRecord = {
  id: number;
  name: string;
  location: string;
  company: string;
  link: string;
  details: string;
  createdat: Date;
  crawled: boolean;
};

export default defineComponent({
  components: {
    CareerEntry,
    draggable,
  },
  setup() {
    const store = useStore();
    const careers = ref<Array<CareerRecord>>([]);
    const career = ref<{ name: string; location: string; company: string; link: string; details: string }>({
      name: '',
      location: '',
      company: '',
      link: '',
      details: '',
    });

    const load = () => {
      career.value = {
        name: '',
        location: '',
        company: '',
        link: '',
        details: '',
      };
      store.dispatch('fetchCareers').then((res: Array<CareerRecord>) => {
        careers.value = res.filter((item: CareerRecord) => !item.crawled);
      });
    };

    onMounted(load);

    const save = () => {
      let error = false;
      if (!career.value.name || career.value.name == '') {
        store.dispatch('addNotification', { message: 'Bitte geben Sie einen Titel ein', type: 'error' });
        error = true;
      }
      if (!career.value.location || career.value.location == '') {
        store.dispatch('addNotification', { message: 'Bitte geben Sie einen Standort ein', type: 'error' });
        error = true;
      }

      if (!career.value.company || career.value.company == '') {
        store.dispatch('addNotification', { message: 'Bitte geben Sie eine Firma ein', type: 'error' });
        error = true;
      }

      if (!career.value.link || career.value.link == '') {
        store.dispatch('addNotification', { message: 'Bitte geben Sie einen Link ein', type: 'error' });
        error = true;
      }

      if (!career.value.details || career.value.details == '') {
        store.dispatch('addNotification', { message: 'Bitte geben Sie Details ein', type: 'error' });
        error = true;
      }

      if (!error) {
        store
          .dispatch('insertCareer', career.value)
          .then(() => {
            store.dispatch('addNotification', { message: 'Gespeichert' });
            career.value = {
              name: '',
              location: '',
              company: '',
              link: '',
              details: '',
            };
            load();
          })
          .catch(() => {
            store.dispatch('addNotification', { message: 'Fehler beim Speichern', type: 'error' });
          });
      }
    };

    const saveOrder = () => {
      const order: Array<{ id: number; order: number }> = [];

      for (let i = 0; careers.value.length > i; i++) {
        order.push({
          id: careers.value[i].id,
          order: i,
        });
      }

      store.dispatch('updateCareerOrder', order);
    };

    return {
      saveOrder,
      careers,
      career,
      save,
      load,
    };
  },
});
</script>


import CareerEntry from '../../components/Backend/Career/CareerEntry.vue';
import { defineComponent, onMounted, ref } from 'vue';
import { useStore } from 'vuex';

import draggable from 'vuedraggable';

type CareerRecord = {
  id: number;
  name: string;
  location: string;
  company: string;
  link: string;
  details: string;
  createdat: Date;
  crawled: boolean;
};

export default defineComponent({
  components: {
    CareerEntry,
    draggable,
  },
  setup() {
    const store = useStore();
    const careers = ref<Array<CareerRecord>>([]);
    const career = ref<{ name: string; location: string; company: string; link: string; details: string }>({
      name: '',
      location: '',
      company: '',
      link: '',
      details: '',
    });

    const load = () => {
      career.value = {
        name: '',
        location: '',
        company: '',
        link: '',
        details: '',
      };
      store.dispatch('fetchCareers').then((res: Array<CareerRecord>) => {
        careers.value = res.filter((item: CareerRecord) => !item.crawled);
      });
    };

    onMounted(load);

    const save = () => {
      let error = false;
      if (!career.value.name || career.value.name == '') {
        store.dispatch('addNotification', { message: 'Bitte geben Sie einen Titel ein', type: 'error' });
        error = true;
      }
      if (!career.value.location || career.value.location == '') {
        store.dispatch('addNotification', { message: 'Bitte geben Sie einen Standort ein', type: 'error' });
        error = true;
      }

      if (!career.value.company || career.value.company == '') {
        store.dispatch('addNotification', { message: 'Bitte geben Sie eine Firma ein', type: 'error' });
        error = true;
      }

      if (!career.value.link || career.value.link == '') {
        store.dispatch('addNotification', { message: 'Bitte geben Sie einen Link ein', type: 'error' });
        error = true;
      }

      if (!career.value.details || career.value.details == '') {
        store.dispatch('addNotification', { message: 'Bitte geben Sie Details ein', type: 'error' });
        error = true;
      }

      if (!error) {
        store
          .dispatch('insertCareer', career.value)
          .then(() => {
            store.dispatch('addNotification', { message: 'Gespeichert' });
            career.value = {
              name: '',
              location: '',
              company: '',
              link: '',
              details: '',
            };
            load();
          })
          .catch(() => {
            store.dispatch('addNotification', { message: 'Fehler beim Speichern', type: 'error' });
          });
      }
    };

    const saveOrder = () => {
      const order: Array<{ id: number; order: number }> = [];

      for (let i = 0; careers.value.length > i; i++) {
        order.push({
          id: careers.value[i].id,
          order: i,
        });
      }

      store.dispatch('updateCareerOrder', order);
    };

    return {
      saveOrder,
      careers,
      career,
      save,
      load,
    };
  },
});
